import React from 'react'
import UnderDevelopment from '../UnderDevelopment/UnderDevelopment'

import classes from './Guide.module.css'

const Guide = (props) => {
    return (
        <UnderDevelopment />
    )
}

export default Guide
