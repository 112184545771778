import React from 'react'

import UnderDevelopment from '../../components/UnderDevelopment/UnderDevelopment'

import classes from './Scoreboard.module.css'

const Scoreboard = (props) => {
    return (
        <UnderDevelopment />
    )
}

export default Scoreboard
